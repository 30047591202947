































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import UserService from "@/services/UserService";
import Constants from "@/utils/Constants";

@Component({
  components: {
    Modal: () => import("@/components/Shared/Modal.vue"),
  },
})
export default class LoginModal extends Vue {
  @Prop({ default: false })
  public show!: boolean;

  private mail: string = "";

  @Watch("show")
  public updateShowValue() {}

  private checkMail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  private validate() {
    if (!this.checkMail(this.mail)) {
      this.$notify({
        group: "globalError",
        type: "error",
        title: this.$t(
          "login.forgotPassword.tokenSent.emailNotCorrect.title"
        ).toString(),
        text: this.$t(
          "login.forgotPassword.tokenSent.emailNotCorrect.description"
        ).toString(),
        duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
      });
      return false;
    }

    UserService.forgotPassword(this.mail, this.$i18n.locale);
    this.$notify({
      group: "global",
      type: "success",
      title: this.$t("login.forgotPassword.tokenSent.title").toString(),
      text: this.$t("login.forgotPassword.tokenSent.confirmation").toString(),
    });
    this.$emit("close");
  }
}
